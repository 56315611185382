import React, { PureComponent } from "react";
import wiki from "./img/wiki.jpg";
class Home extends PureComponent {
  render() {
    return (
      <div>
        <header>
          <div className="header-container">
            <div className="header-description">
              <h1>
              VI Ultramaraton Generała Józefa Dowbora Muśnickiego
              </h1>
              <h3>Lusowo, 27.12.2024</h3>
            </div>
          </div>
        </header>
        <div className="content-container">
          <div className="content">
            <h3>Informacje</h3>
            <p>
              106 km na 106 rocznicę wybuchu Powstania Wielkopolskiego
              Jest to już piąta edycja biegu, który upamiętnia bohaterów Powstania Wielkopolskiego (1918 r.).        
              Tym razem 106 zawodników będzie mogło zmierzyc się z dystansem 106 kilometrów.              
            </p>            
            <a href="https://online.datasport.pl/zapisy/portal/zawody.php?zawody=10387"><span style={{color:'red',fontWeight:"bold", fontSize:28}}>ZAPISY</span></a>
            <h3>Trasa</h3>
            <p>
              Całkowity dystans biegu to 106 kilometry, na które składa się 10 okrążeń, w tym pierwsze okrążenie ma 15 km, a
              pozostałe po 10 km. Trasa wiedzie przez ścieżki o naturalnym podłożu terenu wśród okolicznych łąk i lasów.
              Zawodnik ma możliwość pokonania dowolnej liczby okrążeń. Do klasyfikacji zaliczane są tylko pełne okrążenia.
              <iframe
                title="Trasa biegu"
                frameBorder="0"
                scrolling="no"
                src="https://www.wikiloc.com/wikiloc/spatialArtifacts.do?event=view&id=44342960&measures=on&title=off&near=off&images=off&maptype=M&metricunits=on"
                width="100%"
                height="700"
              ></iframe>
              <br />
              <em>Trasa pojedynczego okrążenia</em>
            </p>
            {/* <h3>Program</h3>
            <div>
              <h5>28.12.2019</h5> <br></br>
              16:30-18:30 Otwarcie biura zawodów - wydawanie numerów startowych
              <br></br>
              18:30 - Odprawa przed biegiem<br></br>
              18:45 - Uroczyste przejście na cmentarz i zapalenie znicza,
              złożenie kwiatów na grobie generała<br></br>
              19:00 - START<br></br>
              <h5>29.12.2019</h5>
              <br></br>
              11:00 - Koniec limitu czasowego. Uroczyste zakończenie biegu.
              <br></br>
            </div> */}
            <h3>Powstanie</h3>
            <p>
              Każdemu zainteresowanemu polecamy zapoznać się z genezą wybuchu
              oraz przebiegiem Powstania Wielkopolskiego.
              <a href="https://pl.wikipedia.org/wiki/Powstanie_wielkopolskie">
                <img src={wiki} alt="Wikipedia - Powstanie wielkopolskie"></img>
              </a>
              <em>
                <a href="https://pl.wikipedia.org/wiki/Powstanie_wielkopolskie">
                  <br></br>
                  Wikipedia o Powstaniu Wielkopolskiem
                </a>
              </em>
            </p>
            <h3>Podziękowania</h3>
            <p>
              Serdecznie dziękujemy darczyńcom i przedsiębiorstwom, które
              pomogły w organizacji biegu.
            </p>
            {/* <ul>
              <li>Gmina Tarnowo Podgórne</li>
              <li>Koło Gospodyń Wiejskich w Lusowie</li>
              <li>Millano Group, Przeźmierowo</li>
              <li>
                Muzeum Powstańców Wielkopolskich. im. Gen. Józefa Dowbora
                Muśnickiego w Lusowie
              </li>
              <li>MS Inwest sp. z o. o., Kiekrz</li>
              <li>Ochotnicza Straż Pożarna w Lusowie</li>
              <li>OSIR Tarnowo Podgórne</li>
              <li>Pałac Jaśminowy Batorowo - Danuta i Marek Kucembowie</li>
              <li>Rada Sołecka, Lusowo</li>
              <li>Restauracja Stara Wozownia, Lusowo - Izabela Hałas</li>
              <li>Restauracja Włoska Robota, Lusówko</li>
              <li>TAR-DROG, Tarnowo Podgórne</li>
              <li>Tarnowski Łącznik, Tarnowo Podgórne</li>
              <li>Towarzystwo Pamięci Gen. Józefa Dowbora Muśnickiego</li>
              <li>Zespół Pieśni i Tańca Lusowiacy</li>
              <li>
                Podziękowania dla uczniów SP w Lusowie za pomoc w oznaczeniu
                trasy.
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    );
  }
}

Home.propTypes = {};

export default Home;
